import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由表
const routes = [
    {
        path: '/',
        component: () => import('../components/layout/index.vue'),
        redirect: "/index",
        children: [
            {
                path: "/index",
                name: "index",
                component: () => import("../views/index/index.vue")
            },
            {
                path: "/storeSystem",
                name: "storeSystem",
                component: () => import("../views/storeSystem/index.vue")
            },
            {
                path: "/yiyueSchool",
                name: "yiyueSchool",
                component: () => import("../views/yiyueSchool/index.vue")
            },
            {
                path: "/legalDeclaration",
                name: "legalDeclaration",
                component: () => import("../components/layout/legalDeclaration.vue")
            }
        ]
    },
    {
        path: '/attract',  //招商加盟
        component: () => import('../components/layout/index.vue'),
        redirect: "/attract",
        children: [
            {
                path: "/attract",
                name: "attract",
                component: () => import("../views/attract/index.vue")
            }
        ]
    },
    {
        path: '/formation',
        component: () => import('../components/layout/index.vue'),
        redirect: "/formation",
        children: [
            {
                path: "/formation",
                name: "formation",
                component: () => import("../views/formation/index.vue")
            }
        ]
    },
    {
        path: '/troduction',  //关于我们
        component: () => import('../components/layout/index.vue'),
        redirect: "/troduction",
        children: [
            {
                path: "/troduction",
                name: "troduction",
                component: () => import("../views/troduction/index.vue")
            }
        ]
    },
    {
        path: '/helpCenter',  //帮助中心
        component: () => import('../components/layout/index.vue'),
        redirect: "/helpCenter",
        children: [
            {
                path: "/helpCenter",
                name: "helpCenter",
                component: () => import("../views/helpCenter/index.vue")
            }
        ]
    },
    {
        path: '/downCenter',  //下载中心
        component: () => import('../components/layout/index.vue'),
        redirect: "/downCenter",
        children: [
            {
                path: "/downCenter",
                name: "downCenter",
                component: () => import("../views/downCenter/index.vue")
            }
        ]
    },
    {
        path: '/richTextArticle',  //富文本页面组件
        component: () => import('../components/layout/index.vue'),
        redirect: "/troduction",
        children: [
            {
                path: "/richTextArticle",
                name: "richTextArticle",
                component: () => import("../components/richTextArticle.vue")
            }
        ]
    }
]

// 修改路由模式
const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

export default router