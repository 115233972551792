/*
 * @Description: 
 * @Author: Darker
 * @Date: 2024-12-21 16:21:07
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-01-08 11:29:26
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import "./style/reset.css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import MetaInfo from 'vue-meta-info'
// 注册 
Vue.use(MetaInfo)
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload) // 注册方式一，使用默认配置
// Vue.use( // 注册方式二，自定义配置相关图片和监听方法类型
//   VueLazyload,
//   {
// 	preLoad: 1.3, // 默认 1.3
//     error: require('./assets/images/default.png'),
//     loading: require('./assets/images/default.png'), // ../dist/default.png
//     attempt: 1, // 默认 3
//     // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
//     listenEvents: ['scroll']
//   }
// )

import anchorFunc from "@/components/anchor"; //锚点使用
import "@/assets/icons"; // 导入图标资源
import SvgIcon from "@/components/SvgIcon.vue"; // svg component
import "./style/public.css"
// import 'lib-flexible/flexible'
import './utils/flexible'
import './style/wangeditor.css'
import { useRichText } from '@zclzone/utils'
Vue.use(useRichText)
// 1. 全局注册SvgIcon组件
Vue.component('svg-icon', SvgIcon)
Vue.config.productionTip = false
Vue.directive('anchor', anchorFunc);
new Vue({
  router,
  render: h => h(App),
  // 预渲染
    mounted () {
      document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')